import * as React from 'react';
import type { PageProps } from 'gatsby';
import Seo from '../components/seo';

const AboutIndex = ({ location }: PageProps) => {
  return (
    <>
      <Seo title="About Us" />
      <p>Some paragraph text and location = {location.pathname}</p>
    </>
  );
};

export default AboutIndex;
